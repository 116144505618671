// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

$primary: #45a505 !default;
$primary-desat: #bff7c6 !default; // NEW
$success: #42ba60 !default;
$info: #7C69EF !default;
$warning: #FAD776 !default;
$danger: #DF4759 !default;